/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      height: '3.50rem',
      marginLeft: '25%',
      marginTop: '1rem',
    },
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'sans-serif',
    ].join(','),
    fontSize: '1.14rem',
    backgroundColor: '#f2ae1c',
  },
  content: {
    fontSize: '1.14rem',
    lineHeight: '1.5',
    marginLeft: '100px',
  },
  content1: {
    fontSize: '1.14rem',
    lineHeight: '1.5',
    color: '#bbb',
  },
  header3: {
    fontSize: '1.64rem',
    fontFamily: [
      'Roboto', 'Helvetica Neue', 'Arial',
    ].join(','),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

function Contact() {
  const classes = useStyles();

  return (
    <section>
      <Container className={classes.root}>
        <div>
          <Typography variant="h4" component="h2" style={{ marginLeft: '120px' }}>
            <strong> Liên lạc với chúng tôi ngay! </strong>
          </Typography>
          <br />
          <Typography className={classes.content}>
            Hãy để lại lời nhắn cho chúng tôi. Chúng tôi sẽ trả lời bạn sớm nhất!
          </Typography>
          <iframe src="https://chinhdai.getflycrm.com/api/forms/viewform/?key=jO9CX2kImAG6U0tuLwNfBTcSaa8t9sP2KSMTyOGVrvVnm7vPZk" title="contact form" width="100%" height="500px" frameBorder="0" marginHeight="50px" marginWidth="0">Loading ...</iframe>
        </div>
      </Container>
    </section>
  );
}

export default Contact;
